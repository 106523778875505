$primary: #ff6371;

/* MAIN COLOR */

$secondary: #ff6371;

/* SECONDARY COLOR */

$blk: #333;

/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */

$wht: #fafafa;
$border-radius: 0px;

/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */

$footerLinks: #fafafa;
html,
body {
    height: 100%;
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    font-weight: bold;
    letter-spacing: 1px;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

p,
ol,
ul {
    font-family: 'Antic Didone', serif;
}

a,h1,h2,h3,h4 {
    font-family: 'Ribeye', cursive;
}


/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

.navbar-default {
    background: url('../img/navbar-bg.png');
    background-repeat: repeat-x;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    transition: all 1s ease;

    .navFade{
            background: transparent;
        
            .navbar-nav > li > a {
                color: white;
            }
        }
            

        &.darkNav {
            background: url(../img/navbar-bg.png) repeat;
            opacity: 0.9;
        }

    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 0px;
            display: flex;
            align-items: center;
            color: $wht;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
        }
    }
}

.navbar-default .navbar-nav>li>a:focus,
{
    color: $wht;
}

.navbar-default .navbar-nav>li>a:hover {
    color: $secondary;
}

.navbar-right {
    margin-top: 20px;
    @media (max-width: 767px) {
        margin-top: 0px;
    }
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: 1px solid $primary;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: url('../img/navbar-bg.png');
    background-repeat: repeat-x;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
            background: transparent;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
            background: transparent;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 200px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 125px;
    }
    .navbar-toggle {
        margin-top: 15px;
    }
}

.top-pad {
    padding: 3em;
    background: white;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}


/* ========= BUTTONS ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited {
    background-color: $secondary;
    border-color: $wht;
    color: $wht;
    padding: 10px 2px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 12px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.btn-download:hover {
    background-color: $primary;
    color: $wht;
}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
    margin-top: 15px;
    @media (max-width: 767px) {
        margin-top: 10px;
    }
    @media (max-width: 1024px) {
        margin-top: 10px;
    }
}


/* =========== HEADER =============== */

.header {
    background: url('../img/banner.jpg');
    background-repeat: no-repeat;
    background-position: 50% 0%;
    background-size: cover;
}

.header-overlay {
    background: rgba(31, 31, 31, .5);
    padding: 275px 0px;
    @media (max-width: 767px) {
        padding: 72px 0px;
    }
}

.header h1 {
    color: $secondary;
    text-shadow: 2px 2px $wht;
    font-size: 4em;
}

.header p {
    color: $wht;
    line-height: 40px;
    letter-spacing: 2px;
    font-size: 1.5em;
    @media (max-width: 1024px) {
        font-size: 1.25em;
        line-height: 35px;
    }
}


/*=========== Section One ================ */

.section-one {
    padding: 75px 0px;
}

.section-one h1 {
    color: $secondary;
    text-shadow: 2px 2px $blk;
}

.section-one h2 {
    color: $primary;
    text-shadow: 1px 1px $blk;
}

.section-one p {
    color: $blk;
    font-size: 1.5em;
    letter-spacing: 1px;
    line-height: 35px;
}

.section-one img {
    @media (max-width: 1199px) {
        margin-bottom: 25px;
    }
}


/*=========== Section Two ================ */

.section-two {
    background: url('../img/navbar-bg.png');
    background-repeat: repeat-x;
    padding: 85px 0px;
    @media (max-width: 1024px) {
        padding: 25px 0px;
        background-repeat: repeat;
    }
}

.section-two h1 {
    color: $secondary;
    text-shadow: 2px 2px $blk;
}

.section-two p {
    color: $wht;
    font-size: 1.5em;
    letter-spacing: 1px;
    line-height: 35px;
}


/*=========== Section Three ================ */

.section-three {
    padding: 75px 0px;
}

.section-three h1 {
    color: $secondary;
    text-shadow: 2px 2px $blk;
}

.section-three p {
    color: $blk;
    font-size: 1.5em;
    letter-spacing: 1px;
    line-height: 35px;
}


/*=========== Section Four ========== */

.section-four {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e98e4e+1,ffffff+100 */
    background: rgb(233, 142, 78);
    /* Old browsers */
    background: -moz-linear-gradient(top, rgba(233, 142, 78, 1) 1%, rgba(255, 255, 255, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(233, 142, 78, 1) 1%, rgba(255, 255, 255, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(233, 142, 78, 1) 1%, rgba(255, 255, 255, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    @media (max-width: 1440px) {
        text-align: center;
    }
}

.section-four h1 {
    color: $secondary;
    text-shadow: 2px 2px $blk;
}

.section-four p {
    color: $blk;
    font-size: 1.5em;
    letter-spacing: 1px;
    line-height: 35px;
}